<template>
  <div class="Personnel">
    <a ref="download" style="display: none" />
    <OrganizationMenu />

    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参建单位">
          <el-select
            size="small"
            v-model="currentUnit"
            placeholder="请选择参建单位"
            clearable
          >
            <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门班组">
          <el-select
            size="small"
            v-model="currentDepartment"
            placeholder="请选择部门/班组"
            clearable
          >
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.labourGroupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="员工姓名">
          <el-input
            size="small"
            v-model="consumerName"
            placeholder="请输入员工姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input
            size="small"
            v-model="certificateOn"
            placeholder="请输入身份证号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="员工工号">
          <el-input
            size="small"
            v-model="consumerNo"
            placeholder="请输入员工工号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="岗位类型">
          <span
            class="filterType"
            @click="ctype = null"
            :class="[ctype == null ? 'filterTypeActive' : '']"
            >不限</span
          >
          <span
            class="filterType"
            @click="ctype = 1"
            :class="[ctype == 1 ? 'filterTypeActive' : '']"
            >职员</span
          >
          <span
            class="filterType"
            @click="ctype = 2"
            :class="[ctype == 2 ? 'filterTypeActive' : '']"
            >劳务工人</span
          >
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="员工状态">
          <span
            class="filterType"
            @click="workingState = null"
            :class="[workingState == null ? 'filterTypeActive' : '']"
            >不限</span
          >
          <!-- <span
            class="filterType"
            @click="workingState = 0"
            :class="[workingState == 0 ? 'filterTypeActive' : '']"
            >未进场</span
          > -->
          <span
            class="filterType"
            @click="workingState = 1"
            :class="[workingState == 1 ? 'filterTypeActive' : '']"
            >在场</span
          >
          <span
            class="filterType"
            @click="workingState = 2"
            :class="[workingState == 2 ? 'filterTypeActive' : '']"
            >退场</span
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click="onAddPerson"
        >添加</el-button
      >
      <el-button
        type="danger"
        icon="el-icon-delete"
        size="small"
        plain
        @click="removePerson"
        >删除</el-button
      >
      <el-button
        type="success"
        icon="el-icon-s-claim"
        size="small"
        plain
        @click="editStatus(1)"
        >进场</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-s-release"
        size="small"
        plain
        @click="editStatus(2)"
        >退场</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-upload"
        @click="dialogVisible = true"
        >批量导入</el-button
      >
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportProject('currentPage')"
        >导出当前数据</el-button
      >
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportProject('all')"
        >导出全部数据</el-button
      >
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <el-checkbox
        v-if="consumerList.length"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选
        <span v-if="checkList.length"
          >当前已选中{{ checkList.length }}人</span
        ></el-checkbox
      >
      <el-checkbox-group v-model="checkList">
        <div
          class="consumer-item"
          v-for="(item, index) in consumerList"
          :key="index"
        >
          <!-- <el-radio v-model="radio" :label="index">{{ "" }}</el-radio> -->
          <div>
            <el-checkbox :label="item.cid" @change="handleCheckedCitiesChange"
              ><br
            /></el-checkbox>
          </div>
          <div style="margin: 10px 20px 10px 10px">
            <el-image
              style="width: 102px; height: 126px"
              :src="item.idPhoto"
              fit="cover"
            ></el-image>
          </div>
          <el-descriptions>
            <template slot="title">
              <div class="title-item">
                <a @click="onReadPerson(item)">{{ item.consumerName }}</a>
              </div>
              <div class="title-item" style="width: 235px">
                <span class="job">{{ getWorkType(item.job) }}</span>
              </div>
              <div class="title-item">
                <span class="title-val"
                  >{{ item.sex == "0" ? "男" : "女" }} | {{ item.age }}岁</span
                >
              </div>
              <div class="title-item">
                <span class="title-val">{{ item.nationality }}族</span>
              </div>
              <div class="title-item">
                <span class="title-val">人脸机：</span>
                <span class="title-val" v-if="item.sendface == '0'">正常</span>
                <span class="title-val" v-if="item.sendface == '2'">未开通</span>
              </div>
            </template>
            <el-descriptions-item label="参建单位">{{
              item.companyName
            }}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{
              item.certificateOn
            }}</el-descriptions-item>
            <el-descriptions-item label="员工工号">{{
              item.consumerNo
            }}</el-descriptions-item>
            <el-descriptions-item label="部门/班组">{{
              item.groupName
            }}</el-descriptions-item>
            <el-descriptions-item label="岗位类型">
              <span v-if="item.ctype == '1'">管理人员</span>
              <span v-else-if="item.ctype == '2'">普通人员</span>
              <span v-else>其他</span>
            </el-descriptions-item>
            <el-descriptions-item label="员工状态">
              <span v-if="item.workingState == '1'">在场</span>
              <span v-else-if="item.workingState == '2'">退场</span>
              <span v-else>未进场</span>
            </el-descriptions-item>
            <el-descriptions-item label="手机号">{{
              item.telePhone
            }}</el-descriptions-item>
            <el-descriptions-item label="地址">{{
              item.addr
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </el-checkbox-group>

      <el-pagination
        v-if="consumerList.length"
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="totals"
        @current-change="getConsumerList"
        @size-change="getConsumerList"
      >
      </el-pagination>

      <el-empty description="暂无数据" v-if="!consumerList.length"></el-empty>
    </div>

    <!-- 弹出框 -->
    <el-drawer direction="rtl" :visible.sync="drawer" size="600px">
      <template slot="title">
        <div>
          <el-button
            type="primary"
            v-if="isRead"
            @click="toEditMode"
            size="small"
            plain
            icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button
            type="primary"
            v-if="!isRead"
            size="small"
            plain
            icon="el-icon-check"
            @click="saveEditData"
            >保存</el-button
          >
          <el-button
            type="danger"
            v-if="!isRead && !isAdd"
            @click="toReadMode"
            size="small"
            plain
            icon="el-icon-close"
            >取消</el-button
          >
        </div>
      </template>
      <div style="padding: 0 20px">
        <!-- 实名信息 -->
        <el-descriptions
          title="实名信息"
          :column="1"
          border
          :labelStyle="{
            width: '120px',
          }"
        >
          <template slot="extra">
            <a style="margin-right: 20px" v-if="!isRead"
              >下载身份证读卡器驱动</a
            >
            <el-button
              v-if="!isRead"
              size="small"
              @click="getIdCardData"
              icon="el-icon-s-custom"
              type="primary"
              >读取二代身份证</el-button
            >
          </template>
          <el-descriptions-item label="身份证头像">
            <el-image
              v-if="isRead"
              style="width: 102px; height: 126px"
              :src="readData.idPhoto"
              fit="contain"
            ></el-image>
            <el-image
              v-if="!isRead"
              style="width: 102px; height: 126px"
              :src="'data:image/jpg;base64,' + editData.photobase64"
              fit="contain"
            >
              <div slot="error" class="image-slot">请读取<br />身份证影像</div>
            </el-image>
          </el-descriptions-item>
          <el-descriptions-item label="身份证号码">
            <span v-if="isRead">{{ readData.cardno }}</span>
            <span v-if="!isRead">{{ editData.cardno }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="姓名">
            <span v-if="isRead">{{ readData.name }}</span>
            <span v-if="!isRead">{{ editData.name }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="性别">
            <span v-if="isRead && readData.sex == '0'">男</span>
            <span v-if="isRead && readData.sex == '1'">女</span>
            <span v-if="!isRead">{{ editData.sex }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="民族">
            <span v-if="isRead">{{ readData.nation }}</span>
            <span v-if="!isRead">{{ editData.nation }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="出生日期">
            <span v-if="isRead">{{ readData.born }}</span>
            <span v-if="!isRead">{{ editData.born }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="住址">
            <span v-if="isRead">{{ readData.address }}</span>
            <span v-if="!isRead">{{ editData.address }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="发证机关">
            <span v-if="isRead">{{ readData.police }}</span>
            <span v-if="!isRead">{{ editData.police }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="有效期限">
            <span v-if="isRead"
              >{{ readData.userlifeb }} 至 {{ readData.userlifee }}</span
            >
            <span v-if="!isRead && editData.userlifeb && editData.userlifee"
              >{{ editData.userlifeb }} 至 {{ editData.userlifee }}</span
            >
          </el-descriptions-item>
        </el-descriptions>

        <!-- 身份证影像 -->
        <el-descriptions
          title="身份证影像"
          :column="1"
          border
          :labelStyle="{
            width: '150px',
          }"
          style="margin-top: 20px"
        >
          <el-descriptions-item label="身份证正面">
            <el-image
              v-if="isRead"
              style="width: 327px; height: 206px"
              :src="readData.frontPic"
              fit="contain"
            ></el-image>
            <el-image
              v-if="!isRead"
              style="width: 327px; height: 206px"
              :src="'data:image/jpg;base64,' + editData.frontImg"
              fit="contain"
            >
              <div slot="error" class="image-slot">请读取身份证影像</div>
            </el-image>
          </el-descriptions-item>
          <el-descriptions-item label="身份证反面">
            <el-image
              v-if="isRead"
              style="width: 327px; height: 206px"
              :src="readData.reversePic"
              fit="contain"
            ></el-image>
            <el-image
              v-if="!isRead"
              style="width: 327px; height: 206px"
              :src="'data:image/jpg;base64,' + editData.backImg"
              fit="contain"
            >
              <div slot="error" class="image-slot">请读取身份证影像</div>
            </el-image>
          </el-descriptions-item>
        </el-descriptions>

        <!-- 人脸录入 -->
        <el-descriptions
          title="人脸信息采集"
          :column="1"
          border
          :labelStyle="{
            width: '150px',
          }"
          style="margin-top: 20px"
        >
          <template slot="extra">
            <el-upload
              action="/"
              :show-file-list="false"
              :auto-upload="true"
              :http-request="uploadFile"
            >
            <el-button
              v-if="!isRead"
              size="small"
              icon="el-icon-video-camera-solid"
              type="primary"
              @click.stop="camraOpen"
              >打开摄像头</el-button
            >
            <el-button
              v-if="!isRead"
              size="small"
              icon="el-icon-video-camera-solid"
              type="danger"
              @click.stop="camraClose"
              >关闭摄像头</el-button
            >
            <el-button
              v-if="!isRead"
              size="small"
              icon="el-icon-camera-solid"
              type="success"
              @click.stop="camraCapture"
              >拍照</el-button
            >
            
            <el-button v-if="!isRead" size="small" type="primary" icon="el-icon-upload">上传</el-button>
          </el-upload>
          </template>
          <el-descriptions-item label="现场采集照片">
            <el-image
              v-if="isRead"
              style="width: 327px; height: 327px"
              :src="readData.photo"
              fit="contain"
            ></el-image>
            <el-image
              v-if="!isRead"
              v-show="!videoShow"
              style="width: 327px; height: 327px"
              :src="'data:image/jpg;base64,' + editData.faceImg"
              fit="contain"
            >
              <div slot="error" class="image-slot">请打开摄像头拍摄</div>
            </el-image>
            <video
              v-if="!isRead"
              v-show="videoShow"
              ref="video"
              autoplay
              style="width: 327px; height: 327px"
            ></video>
            <canvas
              v-if="!isRead"
              style="display: none"
              ref="canvas"
              width="327px"
              height="327px"
            ></canvas>
          </el-descriptions-item>
        </el-descriptions>

        <!-- 职业信息 -->
        <el-descriptions
          title="职业信息"
          :column="1"
          border
          :labelStyle="{
            width: '150px',
          }"
          style="margin-top: 20px; margin-bottom: 20px"
        >
          <el-descriptions-item label="项目名称">
            <span v-if="isRead">{{ readData.proName }}</span>
            <el-select
              v-if="!isRead"
              style="width: 100%"
              v-model="currentProject"
              placeholder="请选择项目"
              :disabled="!isAdd"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.proName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="参建单位">
            <span v-if="isRead">{{ readData.companyName }}</span>
            <el-select
              v-if="!isRead"
              style="width: 100%"
              v-model="currentUnit"
              placeholder="请选择参建单位"
              clearable
              :disabled="!isAdd"
            >
              <el-option
                v-for="item in unitList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="部门/班组">
            <span v-if="isRead">{{ readData.groupName }}</span>
            <el-select
              v-if="!isRead"
              style="width: 100%"
              v-model="currentDepartment"
              placeholder="请选择部门/班组"
              clearable
              :disabled="!isAdd"
            >
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.labourGroupName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="工人类型">
            <span v-if="isRead">{{ readData.ctype }}</span>
            <el-select
              v-model="editData.ctype"
              v-if="!isRead"
              placeholder="请选择工人类型"
              style="width: 100%"
            >
              <el-option
                v-for="item in ctypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="文化程度">
            <span v-if="isRead">{{ getCultureLabel(readData.culture) }}</span>
            <el-select
              v-model="editData.culture"
              v-if="!isRead"
              placeholder="请选择文化程度"
              style="width: 100%"
            >
              <el-option
                v-for="item in cultureList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="进场日期">
            <span v-if="isRead">{{ readData.joinDate }}</span>
            <el-date-picker
              style="width: 100%"
              v-if="!isRead"
              v-model="editData.joinDate"
              format="yyyy-MM-dd hh:mm:ss"
              value-format="yyyy-MM-dd hh:mm:ss"
              align="right"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-descriptions-item>
          <el-descriptions-item label="退场日期">
            <span v-if="isRead">{{ readData.leaveDate }}</span>
            <el-date-picker
              style="width: 100%"
              v-if="!isRead"
              v-model="editData.leaveDate"
              format="yyyy-MM-dd hh:mm:ss"
              value-format="yyyy-MM-dd hh:mm:ss"
              align="right"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-descriptions-item>
          <el-descriptions-item label="工种">
            <span v-if="isRead">{{ getWorkType(readData.job) }}</span>
            <div v-if="!isRead">
              <span
                class="filterType"
                v-for="(item, index) in workTypeList"
                :key="index"
                @click="editData.job = item.sort"
                :class="[editData.job == item.sort ? 'filterTypeActive' : '']"
                >{{ item.label }}</span
              >
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>

    <!-- 数据导入 -->
    <el-dialog title="批量导入" width="410px" :visible.sync="dialogVisible" center>
      <div class="upload-wrap">
        模板地址
        <a
          style="
            margin-left: 10px;
            font-size: 14px;
            line-height: 32px;
            color: #f00;
          "
          href="/api/admin/sys-file/zhgd/419fc4ca368f46d7b3cd6e044def62ec.xlsx"
        >
          下载批量导入模板 </a
        >，填写并核对人员信息。

        <el-form>
          <el-form-item label="项目名称">
            <el-select
              size="small"
              v-model="currentProject"
              placeholder="请选择项目"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.proName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="参建单位">
            <el-select
              size="small"
              v-model="currentUnit"
              placeholder="请选择参建单位"
              clearable
            >
              <el-option
                v-for="item in unitList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门班组">
            <el-select
              size="small"
              v-model="currentDepartment"
              placeholder="请选择部门/班组"
              clearable
            >
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.labourGroupName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-upload
          class="upload-demo"
          drag
          action="/"
          :show-file-list="false"
          :auto-upload="true"
          :http-request="uploadPersonData"
        >
          <i class="el-icon-upload"></i>
          <div  class="el-upload__text">
            将文件拖到此处，或<em>点击上传</em>
          </div>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scope>
.Personnel {
  .el-form-item {
    margin-bottom: 0;
  }
  .consumer-item {
    margin-bottom: 20px;
    border-bottom: 1px dashed #d5d9e4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title-item {
    display: inline-block;
    width: 100px;
  }
  .title-val {
    font-size: 14px;
    font-weight: 400;
  }
  .job {
    padding: 2px 4px;
    background-color: #f00;
    color: #fff;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
  }
  .image-slot {
    height: 100%;
    background: #f5f7fa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #c0c4cc;
    vertical-align: middle;
    text-align: center;
  }
}
</style>

<script>
import OrganizationMenu from "@/components/OrganizationMenu.vue";

export default {
  name: "Personnel",
  components: {
    OrganizationMenu,
  },
  data() {
    return {
      currentProject: null,
      currentUnit: null,
      currentDepartment: null,

      projectList: [],
      unitList: [],
      departmentList: [],
      consumerList: [],
      workTypeList: [],
      cultureList: [
        {
          value: "0",
          label: "小学",
        },
        {
          value: "1",
          label: "初中",
        },
        {
          value: "2",
          label: "高中",
        },
        {
          value: "3",
          label: "中专",
        },
        {
          value: "4",
          label: "大专",
        },
        {
          value: "5",
          label: "本科",
        },
        {
          value: "6",
          label: "硕士",
        },
        {
          value: "7",
          label: "博士",
        },
      ],
      ctypeList: [
        {
          value: null,
          label: "其他",
        },
        {
          value: "1",
          label: "管理人员",
        },
        {
          value: "2",
          label: "普通人员",
        },
      ],

      checkList: [],
      isIndeterminate: false,
      checkAll: false,

      consumerName: "",
      certificateOn: "",
      consumerNo: "",
      ctype: null,
      workingState: null,

      pageNum: 1,
      pageSize: 10,
      totals: 0,

      drawer: false,
      isRead: false,
      isAdd: false,

      readData: {
        cardno: "",
        name: "",
        sex: "",
        nation: "",
        born: "",
        address: "",
        police: "",
        userlifeb: "",
        userlifee: "",
        photobase64: "",
        frontImg: "",
        backImg: "",
        faceImg: "",

        ctype: null,
        culture: "",
        joinDate: "",
        leaveDate: "",
        job: "",
      },
      editData: {
        cardno: "",
        name: "",
        sex: "",
        nation: "",
        born: "",
        address: "",
        police: "",
        userlifeb: "",
        userlifee: "",

        photobase64: "",
        frontImg: "",
        backImg: "",
        faceImg: "",

        ctype: null,
        culture: "",
        joinDate: "",
        leaveDate: "",
        job: "",
      },
      videoShow: false,

      dialogVisible: false,
      fileFormData: null,
      fileReader: null,
    };
  },
  mounted() {
    this.getWorkTypeList(); // 加载工种列表数据
    this.getProjectList(); // 加载项目列表数据
    this.fileReader = new FileReader() // 文件读取对象
  },
  watch: {
    currentProject: function (val) {
      this.currentUnit = null;
      this.pageNum = 1;
      this.getUnitList(); // 加载单位列表数据
      this.getConsumerList(); // 获取人员列表
    },
    currentUnit: function (val) {
      this.currentDepartment = null;
      this.pageNum = 1;
      this.getDepartmentList(); // 加载部门班组列表数据
      this.getConsumerList(); // 获取人员列表
    },
    currentDepartment: function (val) {
      this.pageNum = 1;
      this.getConsumerList(); // 获取人员列表
    },
    ctype: function () {
      this.pageNum = 1;
      this.getConsumerList(); // 获取人员列表
    },
    workingState: function () {
      this.pageNum = 1;
      this.getConsumerList(); // 获取人员列表
    },
    drawer: function (val) {
      console.log("watch drawer =>", val);
      if (!val) {
        this.editData = {
          cid: null,
          cardno: "",
          name: "",
          sex: "",
          nation: "",
          born: "",
          address: "",
          police: "",
          userlifeb: "",
          userlifee: "",

          photobase64: "",
          frontImg: "",
          backImg: "",
          faceImg: "",

          ctype: null,
          culture: "",
          joinDate: "",
          leaveDate: "",
          job: "",
        };
      }
    },
  },
  methods: {
    // 加载工种列表数据
    getWorkTypeList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {};
      that
        .$axiosAdmin({
          method: "get",
          url: "api/admin/dict/type/work_type",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getWorkTypeList =>", res);
          that.workTypeList = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.radio = null;
      that.currentUnit = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载单位列表数据
    getUnitList: function () {
      var that = this;
      that.radio = null;
      var data = {
        companyType: "",
        companyName: "",
        address: "",
        projectId: that.currentProject,
        pageNum: 1,
        pageSize: "",
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/InquireCompany",
          data: data,
        })
        .then((res) => {
          console.log(res);
          that.unitList = res.data.depts;
          // if (res.data.depts.length) {
          //   that.currentUnit = res.data.depts[0].id;
          // }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取部门/班组列表数据
    getDepartmentList: function () {
      var that = this;
      that.radio = null;
      var data = {
        labourGroupName: "",
        projectId: that.currentProject,
        deptId: that.currentUnit,
        pageNum: 1,
        pageSize: 100,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/labourgroup/labourGroupSearch",
          data: data,
        })
        .then((res) => {
          console.log("getDepartmentList =>", res);
          that.totals = res.data.totals;
          that.departmentList = res.data.labourGroupList;
          // if (res.data.labourGroupList.length) {
          //   that.currentDepartment = res.data.labourGroupList[0].id;
          // }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取人员列表
    getConsumerList: function () {
      var that = this;
      that.checkList = [];
      that.checkAll = false;
      var data = {
        projectId: that.currentProject,
        companyId: that.currentUnit,
        groupId: that.currentDepartment,
        consumerName: that.consumerName,
        certificateOn: that.certificateOn,
        consumerNo: that.consumerNo,
        ctype: that.ctype,
        workingState: that.workingState,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgconsumer/queryWgConsumer",
          data: data,
        })
        .then((res) => {
          console.log("getConsumerList =>", res);
          that.totals = res.data.totals;
          that.consumerList = res.data.wgConsumerList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 搜索按钮事件
    searchList: function () {
      this.pageNum = 1;
      this.getConsumerList(); // 获取人员列表
    },
    // 控制全选状态
    handleCheckAllChange(val) {
      console.log("handleCheckAllChange =>", val);
      if (val) {
        this.consumerList.forEach((item, index) => {
          this.checkList.push(item.cid);
        });
      } else {
        this.checkList = [];
      }
      this.isIndeterminate = false;
    },
    // 控制全选框不确定性状态
    handleCheckedCitiesChange(value) {
      let checkedCount = this.checkList.length;
      this.checkAll = checkedCount === this.consumerList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.consumerList.length;
    },
    // 根据 id 获取工种内容
    getWorkType: function (val) {
      if (!val) {
        return;
      }
      var data;
      this.workTypeList.forEach((item) => {
        if (item.sort == val) {
          data = item.label;
        }
      });
      return data;
    },
    getCultureLabel: function (val) {
      if (!val) {
        return;
      }
      var data;
      this.cultureList.forEach((item) => {
        if (item.value == val) {
          data = item.label;
        }
      });
      return data;
    },
    // 读取二代身份证信息
    getIdCardData: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axios({
          method: "get",
          url: "http://localhost:8989/api/ReadMsg?cardImg=1&waitTime=3",
        })
        .then((res) => {
          console.log('读取二代身份证信息=>', res)
          if (res.data.code == '0') {
            that.$notify({
              title: "操作成功",
              message: res.data.retmsg,
              type: "success",
            });
            // 写入数据
            that.editData.cardno = res.data.cardno;
            that.editData.name = res.data.name;
            that.editData.sex = res.data.sex;
            that.editData.nation = res.data.nation;
            that.editData.born = that.getFullTime(res.data.born);
            that.editData.address = res.data.address;
            that.editData.police = res.data.police;
            that.editData.userlifeb = that.getFullTime(res.data.userlifeb);
            that.editData.userlifee = that.getFullTime(res.data.userlifee);
            that.editData.photobase64 = res.data.photobase64;
            that.editData.frontImg = res.data.frontImg;
            that.editData.backImg = res.data.backImg;
          } else {
            that.$notify({
              title: "操作失败",
              message: res.data.retmsg,
              type: "warning",
            });
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.$notify({
              title: "操作失败",
              message: '设备连接失败，请检查设备或网络。',
              type: "warning",
            });
          that.loading.close();
        });
    },
    // 添加人员
    onAddPerson: function () {
      this.isRead = false;
      this.isAdd = true;
      this.drawer = true;
      this.editData = {
        cid: null,
        cardno: "",
        name: "",
        sex: "",
        nation: "",
        born: "",
        address: "",
        police: "",
        userlifeb: "",
        userlifee: "",

        photobase64: "",
        frontImg: "",
        backImg: "",
        faceImg: "",

        ctype: null,
        culture: "",
        joinDate: "",
        leaveDate: "",
        job: "",
      };
    },
    // 查看人员
    onReadPerson: function (data) {
      this.isRead = true;
      this.isAdd = false;
      this.drawer = true;
      console.log("onReadPerson=>", data);
      this.readData = {
        cid: data.cid,
        cardno: data.certificateOn,
        name: data.consumerName,
        sex: data.sex,
        nation: data.nationality,
        born: data.birthday,
        address: data.addr,
        police: data.homeTown,
        userlifeb: data.certificatelifeb,
        userlifee: data.certificatelifee,
        idPhoto: data.idPhoto,
        frontPic: data.frontPic,
        reversePic: data.reversePic,
        photo: data.photo,

        projectId: data.projectId,
        proName: data.proName,
        deptId: data.deptId,
        companyName: data.companyName,
        groupId: data.groupId,
        groupName: data.groupName,
        ctype: data.ctype,
        culture: data.culture,
        joinDate: data.joindate,
        leaveDate: data.leavedate,
        job: data.job,
      };
    },
    // 打开摄像头
    camraOpen: function () {
      this.videoShow = true;
      var video = this.$refs.video;
      //访问摄像头
      if (
        navigator.mediaDevices.getUserMedia ||
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia
      ) {
        //调用用户媒体设备, 访问摄像头
        getUserMedia({ video: { width: 327, height: 327 } }, success, error);
      } else {
        alert("不支持访问用户媒体");
      }

      //访问用户媒体设备的兼容方法
      function getUserMedia(constraints, success, error) {
        if (navigator.mediaDevices.getUserMedia) {
          //最新的标准API
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(success)
            .catch(error);
        } else if (navigator.webkitGetUserMedia) {
          //webkit核心浏览器
          navigator.webkitGetUserMedia(constraints, success, error);
        } else if (navigator.mozGetUserMedia) {
          //firfox浏览器
          navigator.mozGetUserMedia(constraints, success, error);
        } else if (navigator.getUserMedia) {
          //旧版API
          navigator.getUserMedia(constraints, success, error);
        }
      }
      //成功回调
      function success(stream) {
        //兼容webkit核心浏览器
        var CompatibleURL = window.URL || window.webkitURL;
        //将视频流设置为video元素的源
        // console.log(stream);
        //video.src = CompatibleURL.createObjectURL(stream);
        video.srcObject = stream;
        video.play();
      }
      //失败回调
      function error(error) {
        console.log("访问用户媒体设备失败", error);
      }
    },
    // 关闭摄像头
    camraClose: function () {
      this.videoShow = false;
      var video = this.$refs.video;
      video.srcObject.getTracks()[0].stop();
    },
    // 捕捉画面
    camraCapture: function () {
      this.videoShow = false;
      var video = this.$refs.video;
      var canvas = this.$refs.canvas;
      var context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, 327, 327, 0, 0, 327, 327);
      var imageData = canvas.toDataURL("image/jpeg", 1.0);
      console.log("imageData =>", imageData);
      this.editData.faceImg = imageData.split(",")[1];
      this.camraClose();
    },
    // 身份证时间转年月日时分秒
    getFullTime: function (val) {
      if (!val) {
        return;
      }
      return val.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3") + " 00:00:00";
    },
    // 切换到编辑模式
    toEditMode: function () {
      this.isRead = false;
      this.isAdd = false;
      this.currentProject = parseInt(this.readData.projectId);
      setTimeout(() => {
        this.currentUnit = this.readData.deptId;
      }, 500);
      setTimeout(() => {
        this.currentDepartment = this.readData.groupId;
      }, 1000);

      this.editData = {
        cid: this.readData.cid,
        cardno: this.readData.cardno,
        name: this.readData.name,
        sex: this.readData.sex,
        nation: this.readData.nation,
        born: this.readData.born,
        address: this.readData.address,
        police: this.readData.police,
        userlifeb: this.readData.userlifeb,
        userlifee: this.readData.userlifee,

        photobase64: null,
        frontImg: null,
        backImg: null,
        faceImg: null,

        ctype: this.readData.ctype,
        culture: this.readData.culture,
        joinDate: this.readData.joinDate,
        leaveDate: this.readData.leaveDate,
        job: this.readData.job,
      };
      if (this.readData.sex == "0") {
        this.editData.sex = "男";
      }
      if (this.readData.sex == "1") {
        this.editData.sex = "女";
      }
    },
    // 切换到阅读模式
    toReadMode: function () {
      this.isRead = true;
      this.editData = {
        cid: null,
        cardno: "",
        name: "",
        sex: "",
        nation: "",
        born: "",
        address: "",
        police: "",
        userlifeb: "",
        userlifee: "",

        photobase64: "",
        frontImg: "",
        backImg: "",
        faceImg: "",

        ctype: null,
        culture: "",
        joinDate: "",
        leaveDate: "",
        job: "",
      };
    },
    // 保存 EditData 数据
    saveEditData: function () {
      var that = this;
      var data = {
        cid: that.editData.cid,
        projectId: that.currentProject,
        groupId: that.currentDepartment,

        certificateOn: that.editData.cardno,
        consumerName: that.editData.name,
        sex: that.editData.sex,
        nationality: that.editData.nation,
        birthday: that.editData.born,
        addr: that.editData.address,
        homeTown: that.editData.police,
        certificatelifeb: that.editData.userlifeb,
        certificatelifee: that.editData.userlifee,

        idPhoto: that.editData.photobase64,
        frontPic: that.editData.frontImg,
        reversePic: that.editData.backImg,
        photo: that.editData.faceImg,

        ctype: that.editData.ctype,
        culture: that.editData.culture,
        joinDate: that.editData.joinDate,
        leaveDate: that.editData.leaveDate,
        job: that.editData.job,
      };

      if (!data.projectId || !data.groupId) {
        this.$message({
          message: "请选择人员所属项目、参建单位及部门/班组",
          type: "warning",
        });
        return;
      }
      if (
        !data.certificateOn ||
        !data.consumerName ||
        !data.sex ||
        !data.nationality ||
        !data.birthday ||
        !data.addr ||
        !data.homeTown ||
        !data.certificatelifeb ||
        !data.certificatelifee
      ) {
        this.$message({
          message: "请读取二代身份证完善人员实名信息",
          type: "warning",
        });
        return;
      }
      if (!data.ctype || !data.culture || !data.job) {
        this.$message({
          message: "请完善人员职业信息",
          type: "warning",
        });
        return;
      }
      if (!data.idPhoto) {
        data.idPhoto = null;
      } else {
        data.idPhoto = data.idPhoto;
      }
      if (!data.frontPic) {
        data.frontPic = null;
      } else {
        data.frontPic = data.frontPic;
      }
      if (!data.reversePic) {
        data.reversePic = null;
      } else {
        data.reversePic = data.reversePic;
      }
      if (!data.photo) {
        data.photo = null;
      } else {
        data.photo = data.photo;
      }
      if (data.sex == "男") {
        data.sex = 0;
      } else if (data.sex == "女") {
        data.sex = 1;
      }

      console.log("saveEditData => isAdd", this.isAdd, "=> ", data);

      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgconsumer/wgConsumerInsertorUpdate",
          data: data,
        })
        .then((res) => {
          console.log("[RES]wgConsumerInsertorUpdate =>", res);
          if (res.data.code == 1) {
            that.$message({
              message: res.data.msg,
              type: "error",
            });
          }
          that.drawer = false;
          that.getConsumerList();
          that.loading.close();
        })
        .catch((err) => {
          console.log("[ERR]wgConsumerInsertorUpdate =>", err);
          if (err.data.code == 1) {
            that.$message({
              message: res.data.msg,
              type: "error",
            });
          }
          that.loading.close();
        });
    },
    // 批量修改 进场/退场状态
    editStatus: function (status) {
      var that = this;
      var data = {
        wgIdList: this.checkList,
        status: status,
      };
      // return
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgconsumer/editStatusId",
          data: data,
        })
        .then((res) => {
          console.log("editStatus =>", res);
          that.getConsumerList();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 批量删除选中人员
    removePerson: function () {
      var that = this;
      var data = {
        wgIdList: this.checkList,
      };
      // return
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgconsumer/removeById",
          data: data,
        })
        .then((res) => {
          console.log("editStatus =>", res);
          that.getConsumerList();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 导出数据
    exportProject: function (mode) {
      var that = this;
      var timestamp = new Date().getTime();
      if (mode == "currentPage") {
        var data = {
          projectId: that.currentProject,
          companyId: that.currentUnit,
          groupId: that.currentDepartment,
          consumerName: that.consumerName,
          certificateOn: that.certificateOn,
          consumerNo: that.consumerNo,
          ctype: that.ctype,
          workingState: that.workingState,
          pageNum: that.pageNum,
          pageSize: that.pageSize,
        };
      } else if (mode == "all") {
        var data = {
          projectId: that.currentProject,
          companyId: that.currentUnit,
          groupId: that.currentDepartment,
          consumerName: that.consumerName,
          certificateOn: that.certificateOn,
          consumerNo: that.consumerNo,
          ctype: that.ctype,
          workingState: that.workingState,
          pageNum: 1,
          pageSize: null,
        };
      } else {
        return;
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (!data.companyId) {
        data.companyId = "";
      }
      if (!data.ctype) {
        data.ctype = "";
      }
      if (!data.groupId) {
        data.groupId = "";
      }
      if (!data.pageSize) {
        data.pageSize = "";
      }
      if (!data.workingState) {
        data.workingState = "";
      }
      console.log("exportProject =>", data);
      // return
      that
        .$axios({
          method: "post",
          url: "api/admin/wgconsumer/pageDownload",
          data: data,
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              return ret;
            },
          ],
        })
        .then((res) => {
          console.log("exportProject", res);
          console.log("");
          const blob = res.data;
          that.$refs.download.href = URL.createObjectURL(blob);
          that.$refs.download.download = `人员列表${timestamp}.xls`;
          that.$refs.download.click();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 批量导入数据
    uploadPersonData: function (file) {
      var that = this
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.fileFormData = new FormData(); 
      that.fileFormData.append('projectId', that.currentProject);
      that.fileFormData.append('groupId', that.currentDepartment);
      that.fileFormData.append('file', file.file);
      var formData = that.fileFormData
      if (!that.currentProject || !that.currentDepartment) {
        this.$alert('请先选择要导入数据的项目、参建单位及部门/班组', '警告', 
          {
          confirmButtonText: '确定'
          }
        )
        that.loading.close();
        return;
      }
      that
        .$axios({
          method: "post",
          url: "api/admin/wgconsumer/upload",
          data: formData,
          headers: { 
            'Authorization': 'Bearer ' + localStorage.token,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((res) => {
          console.log('uploadPersonData =>', res);

          if (res.data.data.failSize == 0) {
            that.$notify({
              title: "操作成功",
              message: "已成功导入数据",
              type: "success",
            });
            that.$router.push({ name: 'Unit' })
          } else {
            this.$alert(`导入失败的<a href="/api/admin/sys-file/zhgd/${res.data.data.path}">人员列表</a>`, '警告', 
              {
              confirmButtonText: '确定',
              dangerouslyUseHTMLString: true
              }
            )
          }
          that.dialogVisible = false
          that.getConsumerList()
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 上传照片
    uploadFile: function (options) {
      var that = this
      var file = options.file
      var fileReader = this.fileReader
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        console.log('回调=》', fileReader.result)
        that.editData.faceImg = fileReader.result.split(",")[1];
      }
    }
  },
};
</script>